import React from "react"
import { Header, Footer, CookieBanner } from "@barnkids/barnkids-website-shared"
import { createUseStyles } from "react-jss"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

const barnkids = [
  {
    name: "Nursery",
    dropDown: [
      { name: "The Nest (3-18m)", to: "/rooms/nest" },
      { name: "The Hatchery (18-28m)", to: "/rooms/hatchery" },
      { name: "Barn (2-3yrs)", to: "/rooms/barn" },
      { name: "Paddock and Stables (3-4yrs)", to: "/rooms/paddock" },
    ],
  },
  { name: "Forest School", to: "/rooms/forest" },
  { name: "Info", 
  dropDown: [
    { name: "Our Vision", ext: "https://www.barnkidsnurseries.co.uk/vision" },
    { name: "Blog Posts", ext: "https://www.barnkidsnurseries.co.uk/blog"},
    { name : "Holiday Club", to: "/info/holiday-club" },
    { name: "Team", to: "/info/team" },
    { name: "Fees", to: "/info/fees" },
    { name: "Policies", to: "/info/policies"},
    { name: "Come and Visit", to: "/info/visit" },
    { name: "Register", to: "/info/register" },

  ] },
  { name: "Careers", ext: "https://www.barnkidsnurseries.co.uk/careers" },
]

const useStyles = createUseStyles({
  "@global": {
    body: {
      margin: 0,
      fontSize: 14,
      fontFamily: [
        "p22-underground",
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        "sans-serif",
      ],
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
    },
    p: {
      padding: "10px 0",
    },
  },
})

const query = graphql`
{
  site: sanitySiteInfo(SiteName: {eq: "Barnkids"}) {
    SiteName
    _rawDescription (resolveReferences: {maxDepth: 5})
    _rawAddress (resolveReferences: {maxDepth:5})
    TelNumber
    email
    facebook
    instagram
    map
    OfstedURN
  }
}`

const Layout = ({ children, data, pageContext, ...props }) => {
  pageContext = {site:'barnkids', ...pageContext}
  const staticQuery = useStaticQuery(query)
  console.log('static', staticQuery)
  useStyles()
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Barnkids"
        />
        <meta name="title" content="Barnkids Nursery and Forest School" />
      </Helmet>
      <Header pageContext={pageContext} menu={barnkids} siteData={staticQuery.site} {...props}/>
      {React.Children.map(children, (child) =>{
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { pageContext});
        }
        return child;
      })}
      <CookieBanner/>
      <Footer pageContext={pageContext} menu={barnkids} siteData={staticQuery.site} {...props}/>
 
    </React.Fragment>
  )
}

export default Layout
